@import "./constants/fonts.css";
body {
  font-family: "SkyTextRegular";
  font-size: 1rem;
  background: #fff;

  min-width: 750px;
}
.App {
  text-align: center;
  position: relative;
  z-index: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.scene {
  position: relative;
  height: 100vh;
  width: 100%;
  background: #fff;
  z-index: 1000;
  overflow-y: scroll;
}
h4 {
  font-family: "SkyTextMedium";
  font-weight: normal;
}
button{
  font-family: "SkyTextMedium";
  font-weight: normal;
}
